import { SplitApi } from "../api-v2";

export const cancelApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    cancelOrder: builder.mutation({
      query: (record) => {
        
        // Basic validation
        if (!record || !record._id) {
          throw new Error("Invalid withdrawal record");
        }
        
        return {
          url: `/orders/withdraws/${record._id}/cancel`,
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          timeout: 15000, // 15 seconds timeout
        };
      },
      
      // Handle errors consistently based on status code
      validateStatus: (response, result) => {
        // Consider only 2xx status codes as success
        return response.status >= 200 && response.status < 300;
      },
      
      // Simplify response transformation
      transformResponse: (response) => {
        // Just pass through the response
        return response;
      },
      
      // Ensure errors are handled consistently
      transformErrorResponse: (response) => {
        // Don't need detailed error messages, just return the status
        return {
          status: response.status,
          error: true
        };
      },
      
      // Invalidate relevant caches on successful cancellation
      invalidatesTags: (result) => {
        if (result && !result.error) {
          return [{ type: 'Orders', id: 'LIST' }];
        }
        return [];
      }
    })
  }),
  overrideExisting: false
});

export const { useCancelOrderMutation } = cancelApi;